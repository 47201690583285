.table-responsive {
  .react-bootstrap-table {
    .table {
      thead {
        background: #e1f3ff;
      }
      td{
        padding: 0.575rem;
      }
      th{
        padding: 0.575rem;
      }
    }
  }
}
