body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.dropzone {
  cursor: pointer;
  vertical-align: middle;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35%;
  border: double;
  border-radius: 12px;
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}
.react-bootstrap-table.table.table-responsive.table-striped.table-hover {
  max-height: 500px;
}
.react-bootstrap-table.table.table-responsive.table-striped.table-hover thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
