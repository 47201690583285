.nav-tabs{
  background: #ECEEF2;
  border-radius: 6px;
  .nav-link.active{
    border-color: #ebedf2 #ebedf2 #ebedf2;
    color: #e1f3ff;
    // color: #1e39a1;
    font-weight: 500;
    border-radius: 6px;
    background: #1e39a1;
  }
  .nav-link{
    border-radius: 6px;
    background: #ECEEF2;
  }
}