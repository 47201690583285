.yearform {
  display: block;
  border-radius: 6px;

  box-shadow: none;
  background-clip: padding-box;
  background-color: #f0f0f0;
  border: 1px solid #ebedf2;
  font-size: 0.8125rem;
  padding: 0.5rem;
  font-weight: 400;
  line-height: 1;
  color: #202122;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ispace {
  padding-left: 7px;
  padding-right: 7px;
}
.award {
  display: flex;
  justify-content: space-between;
}
.dochead {
  padding-top: 13px;
}
.year {
  width: 50px;
}
.actionicon {
  display: flex;
  justify-content: space-between;
}
.emtable1 {
  display: block !important;
  width: 100% !important;
  overflow-x: auto !important;
}
.tabres{
  display: block ;
  height: 100% ;
  overflow-y: auto;
}
.tabser{
  display: block ;
  height: 100% ;
  overflow-x: auto;
}