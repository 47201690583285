.adhead{
      display: flex;
      justify-content: space-between;
      padding: 15px;
}
.in{
      padding: 5px;
}
.custin{
      display: flex;
      justify-content: center;
}
.sel{
      margin-top: 5px;
}
.seel{
      margin-top: 7px;
}
// .btn-sm{
//       background: linear-gradient(to left,rgb(52, 5, 99),rgb(0, 183, 255)) !important;
// }
.opse{
      font-family: 'Times New Roman', Times, serif;
      padding: 4px;
      
    }